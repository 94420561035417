<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <router-link :to="'/doctors'" class="btn btn-success mb-3">
              <i class="mdi mdi-arrow-bottom-left-thick me-1"></i> Back
            </router-link>
          </div>
          <div id="header-accordion" class="custom-accordion">
            <!-- Page Genel Ayarlar -->
            <div class="card">
              <div class="p-4 border-top">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label for="namePage">Category <strong>*</strong></label>

                      <multiselect
                        v-model="formData.category_id"
                        :options="options"
                        :multiple="true"
                        :taggable="true"
                        placeholder="Category Name"
                        tag-placeholder="Add this as new tag"
                        label="name"
                        track-by="name"
                      >
                        <template slot-scope="{ values }"
                          ><span>{{ values.name }} </span></template
                        >
                      </multiselect>
                      <div>
                        Seçili Değerler:
                        <ul>
                          <li
                            v-for="(value, key) in formData.category_id"
                            :key="key"
                          >
                            {{ value }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Page Genel Ayarlar -->
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-end ms-1">
          <button
            type="button"
            class="btn btn-danger"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-times me-1"></i> Cancel
          </button>
          <button
            type="submit"
            @click="save()"
            class="btn btn-success ms-1"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-file-alt me-1"></i> Save
          </button>
        </div>
        <!-- end col -->
      </div>
    </form>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { API } from "@/state/API";
import { POPUP } from "@/state/popup";
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Doctors Category",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: "Doctors Category",
      items: [
        {
          text: "Doctors Category",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      createSend: 0,
      formData: {},
      value1: null,
      langs: [],
      options: [],
    };
  },
  middleware: "authentication",
  mounted() {
    this.FindLp();
  },
  methods: {
    FindLp() {
      API.Get(API.Doctors.Category.List + this.$route.params.id)
        .then((response) => {
          console.log(response.data.doctorsAll);

          if (response.data.status == "success") {
            this.options = response.data.order;
            // var category_id = [];
            // response.data.doctorsAll.forEach((element) => {
            //   category_id.push({
            //     id: element.category.id,
            //     name: element.category.name,
            //   });
            // });

            // this.formData.category_id = category_id;
            // console.log(this.formData.category_id);
          }
        })
        .catch((error) => {
          console.error("Error while fetching data:", error);
        });
    },
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        if (this.formData.category_id) {
          this.formData.category_id = JSON.stringify(this.formData.category_id);
          let parsedData = JSON.parse(this.formData.category_id);
          this.formData.category_id = parsedData.map((item) => item.id);
          _formData.append(key, this.formData.category_id);
        } else {
          _formData.append(key, this.formData[key]);
        }
      });

      return _formData;
    },
    async save() {
      this.createSend = 1;
      var data;

      const response = await API.Post(
        API.Doctors.Category.Update + this.$route.params.id,
        this.convertToFormData()
      );

      console.log(response.data);
      console.log(this.formData.category_id);
      if (response.data.status == "success") {
        this.createSend = 0;

        data = "Your work has update";
        POPUP.popupClick(data);
        this.FindLp();
      } else {
        this.createSend = 0;
        data = "Error";
        POPUP.popupClickNot(data);
      }
    },

    fileChange(fieldName, $event) {
      if ($event.target.files && $event.target.files.length) {
        this.formData[fieldName] = $event.target.files[0];
      }
    },
  },
};
</script>

<style scoped>
strong {
  color: rgba(239, 4, 4, 0.39);
}
.border-col {
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 10px;
}
</style>
